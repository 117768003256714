export default [
  {
    path: '/maps/editor',
    name: 'map-editor',
    component: () => import('@/pages/maps/Editor.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/config',
    name: 'map-config',
    component: () => import('@/pages/maps/Config.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/custom-styles',
    name: 'custom-styles',
    component: () => import('@/pages/maps/CustomStyles.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/custom-objects',
    name: 'custom-objects',
    component: () => import('@/pages/maps/CustomObjects.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/geojson-datasets',
    name: 'geojson-datasets',
    component: () => import('@/pages/maps/GeojsonDatasets.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/floors',
    name: 'map-floors',
    component: () => import('@/pages/maps/MapFloors.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/stairs',
    name: 'map-stairs',
    component: () => import('@/pages/maps/Stairs.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/lighting',
    name: 'map-lighting',
    component: () => import('@/pages/maps/Lighting.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/backgrounds',
    name: 'map-backgrounds',
    component: () => import('@/pages/maps/Backgrounds.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
  {
    path: '/maps/texts',
    name: 'map-texts',
    component: () => import('@/pages/maps/Texts.vue'),
    meta: {
      requiresAuth: true,
      showVenueSelector: true,
    },
  },
];
