import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import stylesModule from './modules/styles';
import objectsModule from './modules/objects';
import datasetsModule from './modules/datasets';
import floorsModule from './modules/floors';
import stairsModule from './modules/stairs';
import lightsModule from './modules/lights';
import backgroundsModule from './modules/backgrounds';
import suitesModule from './modules/suites';
import textsModule from './modules/texts';

const state = {
  apiKeys: [],
  activeOrganization: [],
  organizations: [],
  members: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    styles: stylesModule,
    objects: objectsModule,
    datasets: datasetsModule,
    floors: floorsModule,
    stairs: stairsModule,
    lights: lightsModule,
    backgrounds: backgroundsModule,
    suites: suitesModule,
    texts: textsModule,
  },
};
