const state = {
  texts: [],
};

const getters = {
  texts: (state) => state.texts,
};

const mutations = {
  setTexts(state, texts) {
    state.texts = texts;
  },
};

const actions = {
  fetchTexts: async ({ commit, rootGetters }) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    const texts = await skyos.texts.list(venueId);
    commit('setTexts', texts);
  },

  create: async ({ rootGetters }, newText) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.texts.create(newText, venueId);
  },

  edit: async ({ rootGetters }, editedText) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.texts.update(editedText, venueId);
  },

  delete: async ({ rootGetters }, textId) => {
    const skyos = rootGetters['app/skyOSService'];
    const venueId = rootGetters['content/venues/activeVenueId'];
    await skyos.texts.delete(textId, venueId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
