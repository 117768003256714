import BaseResource from './base';
import { NotFoundError, ValidationError } from '../../utils/errors';

export default class MapTextsResource extends BaseResource {
  async create(newText, venueId) {
    const res = await this.http.post(`v2/venues/${venueId}/map_texts`, newText);

    return Promise.resolve(res.data.data);
  }

  async delete(id, venueId) {
    const endpoint = `v2/venues/${venueId}/map_texts/${id}`;
    const res = await this.http.delete(endpoint);

    return Promise.resolve(res.data.data);
  }

  async fetchById(textId, venueId) {
    const endpoint = `v2/venues/${venueId}/map_texts/${textId}`;
    const res = await this.http.get(endpoint);

    return Promise.resolve(res.data.data);
  }

  async list(venueId) {
    const res = await this.http.get(`v2/venues/${venueId}/map_texts`);
    return Promise.resolve(res.data.data);
  }

  async update(updatedText, venueId) {
    if (!updatedText || !updatedText.id) {
      return Promise.reject(new ValidationError('missing required field "id" in update text payload'));
    }
    if (!venueId || typeof venueId !== 'number' || venueId < 1) {
      return Promise.reject(new NotFoundError('missing or invalid required field "venueId" in path'));
    }
    const endpoint = `v2/venues/${venueId}/map_texts/${updatedText.id}`;
    const res = await this.http.put(endpoint, updatedText);

    return Promise.resolve(res.data.data);
  }
}
